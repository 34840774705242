<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card>
            </template>
            <b-jumbotron>
                <h4>
                    <router-link :to="{ name: 'events' }" class="mr-2">
                        <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
                    </router-link>
                    Event Settings
                </h4>
                <b-alert :show="event.event_type == 1" variant="info" class="mt-4">
                    <b><b-icon-info-circle></b-icon-info-circle> This event is a "ticketed" event. </b>
                    <router-link :to="{ name: 'event_ticket_settings', params: { id: event.id } }">Ticket Settings</router-link> |
                    <router-link :to="{ name: 'event_ticket_manager', params: { id: event.id } }">Ticket Manager</router-link>
                </b-alert>
                <b-form class="mt-4">
                    <b-form-group
                        label="Title"
                    >
                        <b-input v-model="event.title" />
                    </b-form-group> 
                    <b-form-group
                        label="Description"
                    >
                        <b-textarea v-model="event.description"></b-textarea>
                    </b-form-group>
                    <b-form-group
                        label="Event Type"
                    >
                        <b-select :options="event_types" v-model="event.event_type"></b-select>
                    </b-form-group>
                    <b-form-group
                        label="Event Date"
                    >
                        <b-input type="datetime-local" v-model="event.event_date"></b-input>
                    </b-form-group>
                    <div>
                        <h6>
                            Location
                        </h6>
                    </div>
                    <b-form-group
                        label="Location Name"
                    >
                        <b-input v-model="event.loc_name" />
                    </b-form-group>
                    <b-form-group
                        label="Address"
                    >
                        <b-input v-model="event.loc_addr" />
                    </b-form-group>
                    <b-form-group
                        label="City"
                    >
                        <b-input v-model="event.loc_city" />
                    </b-form-group>
                    <b-form-group
                        label="State"
                    >
                        <b-input v-model="event.loc_state" />
                    </b-form-group>
                    <b-form-group
                        label="Zip"
                    >
                        <b-input v-model="event.loc_zip" />
                    </b-form-group>
                    <b-form-group
                        label="Event Registration Begins (date)"
                    >
                        <b-input type="datetime-local" v-model="event.start_date"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Event Registration Ends (date)"
                    >
                        <b-input type="datetime-local" v-model="event.stop_date"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Toggle Settings"
                    >
                        <b-checkbox v-model="event.add_calendar_link" switch>Include "Add to Calendar" link</b-checkbox>
                        <b-checkbox v-model="event.view_maps_link"    switch>Include "View on Map" link</b-checkbox>
                        <b-checkbox v-model="event.show_on_events_page" switch>Show on "Events" Page</b-checkbox>
                    </b-form-group>
                    <div class="text-center mt-4">
                        <b-button
                            :disabled="saving"
                            variant="primary"
                            size="lg"
                            @click="saveEvent()"
                            pill
                        >
                            <b-spinner v-if="saving" variant="light"></b-spinner>
                            <font-awesome-icon v-else icon="save"></font-awesome-icon>
                            Save Event
                        </b-button>
                    </div>
                </b-form>
            </b-jumbotron>
        </b-skeleton-wrapper>
    </b-container>
</template>
<script>
export default {
    props: ["id"],
    data() {
        return {
            event_types: [
                { text: "Ticketed", value: 1 }
            ],
            event: {},
            loading: false,
            saving: false
        };
    },
    mounted() {
        this.loadEvent();
    },
    methods: {
        loadEvent() {
            this.loading = true;
            this.$api.get(`/api/events/admin/${this.id}/fetch`).then((response) => {
                this.event = response.data.event;
                this.loading = false;
            });
        },
        saveEvent() {
            this.saving = true;
            this.$api.post(`/api/events/admin/${this.id}/save`, this.event).then((response) => {
                this.$bvToast.toast(response.data.message, {
                    title: "Notification",
                    autoHideDelay: 5000,
                    appendToast: true
                });
                this.saving = false;
            });
        }
    }
}
</script>